import {breakpoints} from "../constants/ui";
import { window } from 'browser-monads';

export const replaceSubDomain = (str) => str.replace(/new./, 'dashboard.');

export const windowSizeChecker = {
  isDesktop: () => window.innerWidth >= breakpoints.xl,
  isNotebook: () => window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xl,
  isBigTablet: () => window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xlg,
  isTablet: () => window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg,
  isSmallTablet: () => window.innerWidth > breakpoints.sm && window.innerWidth < breakpoints.md,
  isMobile: () => window.innerWidth <= breakpoints.sm,
  currentWidth: window.innerWidth
}
