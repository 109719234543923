import React from 'react';
import { createMarkup } from '../../helpers/createMarkup';

const HowWeWorkCard = ({ number, desc, img, title }) => {
  require.context('../../images/how-we-work-page', true);

  return (
    <div className="hww-card">
      <div className="row">
        <div className="col-lg-6 hww-card-info">
          <div className="hwwc-number">0{number}</div>
          <div className="hwwc-title">{title}</div>
          <div
            className="hwwc-desc"
            dangerouslySetInnerHTML={createMarkup(desc)}
          />
        </div>
        <div className="col-lg-6 hww-card-img">
          <img alt="" src={img} />
        </div>
      </div>
    </div>
  );
};

export default HowWeWorkCard;
