import React, { useState } from 'react';
import ContactUsAndThanks from '../components/CombineModals/ContactUsAndThanks';
import HowWeWorkCard from '../components/HowWeWorkCard';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import TitleImgBlock from '../components/TitleImgBlock';
import i18n from '../services/translation/i18next';
import { replaceSubDomain } from '../utils';

const renderCards = (source) => {
  return source.content.map((item, id) => {
    return (
      <HowWeWorkCard
        key={id}
        number={id + 1}
        title={item.title}
        desc={item.description}
        img={replaceSubDomain(item.icon_inactive.desktop)}
      />
    );
  });
};

const renderOptions = (source) => {
  return source.want_list.map((item, idx) => {
    return (
      <div key={idx} className="hww-option">
        <div className="icon-img">
          <img alt="" src={replaceSubDomain(item.icon.desktop)} />
        </div>
        <div className="hww-opt-title">
          <span>{item.icon_description}</span>
        </div>
      </div>
    );
  });
};

const HowWeWorkPage = ({ pageContext: { data: source } }) => {
  const data = i18n.t('howWeWorkPage', { returnObjects: true });
  const imageContext = require.context('../images/how-we-work-page', true);
  const img = imageContext('./' + data.title_img);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  return (
    <Layout>
      <SEO title="How we work" />
      <section className="section page-services page-default">
        <TitleImgBlock
          image={replaceSubDomain(source.header.image.desktop)}
          title={source.header.title}
        />
        <div className="container mt-0 mt-md-5 represent">
          {renderCards(source)}
          <div className="hww-b-panel">
            <div className="h1 text-center">{source.content_footer.title}</div>
            <div className="hww-options">
              <div className="hww-wrapper">{renderOptions(source)}</div>
            </div>
            <div className="hmm-comment">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 comment">
                  {source.content_footer.description}
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary btn-lg rounded-pill"
              onClick={openModalHandler}
              children={source.content_footer.button_text}
            />
          </div>
        </div>
      </section>

      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </Layout>
  );
};

export default HowWeWorkPage;
